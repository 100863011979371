import { FC, createRef, memo } from 'react';
import styles from '../../../ExpensionMap.module.scss';
import cx from 'classnames';
import { useExpansionMapHelper } from '../../../helper';
import { Districts } from '../../../../../constants/districts';

export const Junkersdorf: FC = memo(() => {
  const ref = createRef<SVGTextElement>();
  const {
    district,
    expansionStatusClass,
    isSelected,
    handleClick,
    handleHover,
  } = useExpansionMapHelper({
    district: Districts.Junkersdorf,
    ref,
  });

  return (
    <>
      <g
        className={cx(styles.districtWrapper, {
          [styles.isSelected]: isSelected,
        })}
        id={district}
        onClick={handleClick}
        onMouseEnter={handleHover}
      >
        <path
          className={cx(styles[expansionStatusClass], styles.districtArea)}
          aria-label="Junkersdorf"
          d="M227.7,723.8c-1.3-3.9-0.6-4.7,2-7.6c0.7-2.7,0.7-7.4,1.2-10.2h4.1c22.6-49.6,17.9-39.8,16.9-93l7.1-1l2,8 l12,4l2,12l24,4l4,12v22h21l-14,16c0.6,0.9,17.5,60.1,18.5,60.7l-7.4,5.5c-1.1,1.5-4.9,5.8-6.2,7.3c-5.8,4.1-14.1,9.8-20.7,12.5 c-0.1-0.1-0.2-0.5-0.3-0.6c-4.9,1.8-11,3.6-15.3,6.6c-10.1-6.4-23.5-15.6-33.6-22v-0.8c-0.6-0.3-1.4-1-2-1.3 c-0.2-2.5-0.7-6.9-1.4-9.3l-0.3-0.1c-2.3-8.2-6.8-16.5-9.4-24.6c-0.9,0-3.3-0.1-4.1,0.3L227.7,723.8z"
        />
        <text
          className={styles.districtName}
          ref={ref}
          transform="matrix(1 0 0 1 244.4167 729.1332)"
        >
          Junkersdorf
        </text>
      </g>
    </>
  );
});
